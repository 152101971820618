body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAFAFA !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.am-card{
  min-height: 20px !important;
}
.am-list-extra{
  color: #FF6E3C !important;
}
.am-list-item img {
  width: 45px !important;
  height: 45px !important;
  vertical-align: middle;
}
.cangetyan{
  background-color: #30CC75;
}
.cantgetyan{
  background-color: #F0EFF5;
}